.beReset {
	.quick_fact {
		padding: 10px 20px;
		background-color: var(--kc-light-gray);
		border: 1px solid var(--kc-light-gray-shd1);

		.number-wrapper { color: var(--kc-gold) !important; }
		hr { display: block; background-color: rgba(0,0,0,0.3); }
	}

	.google-map-contact-wrapper.style-box {
		margin-left: 260px !important;

		.get_in_touch { 
			width: 350px;
		
			.kc-map-title {
				color: #000;
				font-size: .8em;
			}

			ul li .icon {
				color: #000;
				top: 12px;
			}
		}

	}
}


/* ##
 * # Login Page
 * # ---
 * ### */
.login {
	background: var(--kc-dark-blue-tr);

	a { 
		color: var(--kc-gold) !important;

		&:hover { color: var(--kc-gold-shd1) !important; }
	}

	#login { 
		> h1 a { 
			background-image: url('/wp-content/uploads/2022/05/Kaiser_Caulking-Logotype.png');
			width: 100%;
			height: 100px;
			background-size: 100%;
		}

		#loginform,
		#loginform input {
			border-color: var(--kc-gold);
		}

		#loginform .submit #wp-submit {
			background-color: var(--kc-gold);

			&:hover { background-color: var(--kc-gold-shd1); }
		}

	}
}

/* ##
 * # Contact Form 7
 * # ---
 * ### */
 .wpcf7-response-output {
	display: inline-block;
    width: 100% !important;
    text-align: center;
    float: none !important;
    position: relative !important;
    margin: 0 auto !important;
    color: var(--kc-blue);
}


/* ##
 * # Featured Projects - Image Links
 * # ---
 * ### */
#kc-featured-projects {
	.image_links a:hover {
		background: var(--kc-dark-blue-tr);
		.path { stroke: var(--kc-gold); }
	}
}

/* ##
 * # Buttons
 * # ---
 * ### */
.button-stroke {
	& input[type="submit"]:hover { color: #fff !important; }

	
	a.action_button { 
		color: #fff !important;
		background-color: var(--kc-gold) !important; 
		&:hover { background-color: var(--kc-gold-shd1) !important; }
	}
}


/* ##
 * # Top Var -- Main Menu
 * # ---
 * ### */
#Top_bar #menu-main-menu {
	
	li.hover { background-color: var(--kc-dark-blue-tr); }

	/** Sub Menu */
	.sub-menu {
		
		background: transparent;
		li { 
			background-color: var(--kc-dark-blue-tr); 
			width: 250px;
		}
	} 

}
#Top_bar:not(.is-sticky) li.hover { background-color: var(--kc-dark-blue-tr); }
#Top_bar.is-sticky .menu > li:not(.current-menu-item) > a { color: #fff; }




/**
 * SubHeader
 * ---
 * */
 #Subheader {
	h1 {
		text-shadow: rgb(0 0 0) 1px 1px 2px;
		text-align: right;
	}
}



/**
 * Footer
 * ---
 * */
#Footer .footer_copy { padding: 20px 0; }