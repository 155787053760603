:root{
	--kc-gold			: #ccaa3d;
	--kc-gold-shd1		: #efbe1e;
	--kc-blue			: #0063a4;
	--kc-blue-shd1		: #204b66;
	--kc-dark-gray		: #333333;
	--kc-light-gray		: #f4f4f4;
	--kc-light-gray-shd2	: #a0a0a0;
	--kc-light-gray-shd1	: #e4e4e4;
	--kc-dark-blue-tr: rgb(17 32 53 / 93%);
	--kc-dark-blue-tr-85: rgb(17 32 53 / 85%);
	
	.kc-hl-gold {
		color: var(--kc-gold);
	}
}