/* ##
 * # 10. Responsiveness
 * ### */
 
	/* ### 10.1 Mobile ### */
		@media only screen and (max-width: 480px) {
			.beReset .google-map-contact-wrapper.style-box {
				margin-left:0 !important;
			}
		}

		@media only screen and (max-width: 639px) {}
	/* End Mobile */



	/* ### 10.2 Tablets ### */
		@media only screen and (max-width: 820px) {
			/** Wrap Overlap */
			// #kc-wrap-overlap .mcb-wrap-inner { top: -170px !important; }
		}
		
		@media only screen and (max-width: 1024px) { }
		/* End Tablets */
		
	@media only screen and (max-width: 1240px) {
		#Top_bar .top_bar_right { margin-top: initial !important; }
	}
/* END Responsiveness */