:root {
  --kc-gold: #ccaa3d;
  --kc-gold-shd1: #efbe1e;
  --kc-blue: #0063a4;
  --kc-blue-shd1: #204b66;
  --kc-dark-gray: #333333;
  --kc-light-gray: #f4f4f4;
  --kc-light-gray-shd2: #a0a0a0;
  --kc-light-gray-shd1: #e4e4e4;
  --kc-dark-blue-tr: rgb(17 32 53 / 93%);
  --kc-dark-blue-tr-85: rgb(17 32 53 / 85%);
}
:root .kc-hl-gold {
  color: var(--kc-gold);
}

/* ##
 * # 03. Elements 
 * ### */
.kc-hl-box-gold {
  background-color: var(--kc-gold);
  color: #fff;
}
.kc-hl-box-gold:hover {
  background-color: var(--kc-gold-shd1);
}

.kc-list-gold li::before {
  color: var(--kc-gold);
}

#kc-home-projects {
  margin-bottom: -180px;
}

.beReset .quick_fact {
  padding: 10px 20px;
  background-color: var(--kc-light-gray);
  border: 1px solid var(--kc-light-gray-shd1);
}
.beReset .quick_fact .number-wrapper {
  color: var(--kc-gold) !important;
}
.beReset .quick_fact hr {
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
}
.beReset .google-map-contact-wrapper.style-box {
  margin-left: 260px !important;
}
.beReset .google-map-contact-wrapper.style-box .get_in_touch {
  width: 350px;
}
.beReset .google-map-contact-wrapper.style-box .get_in_touch .kc-map-title {
  color: #000;
  font-size: 0.8em;
}
.beReset .google-map-contact-wrapper.style-box .get_in_touch ul li .icon {
  color: #000;
  top: 12px;
}

/* ##
 * # Login Page
 * # ---
 * ### */
.login {
  background: var(--kc-dark-blue-tr);
}
.login a {
  color: var(--kc-gold) !important;
}
.login a:hover {
  color: var(--kc-gold-shd1) !important;
}
.login #login > h1 a {
  background-image: url("/wp-content/uploads/2022/05/Kaiser_Caulking-Logotype.png");
  width: 100%;
  height: 100px;
  background-size: 100%;
}
.login #login #loginform,
.login #login #loginform input {
  border-color: var(--kc-gold);
}
.login #login #loginform .submit #wp-submit {
  background-color: var(--kc-gold);
}
.login #login #loginform .submit #wp-submit:hover {
  background-color: var(--kc-gold-shd1);
}

/* ##
 * # Contact Form 7
 * # ---
 * ### */
.wpcf7-response-output {
  display: inline-block;
  width: 100% !important;
  text-align: center;
  float: none !important;
  position: relative !important;
  margin: 0 auto !important;
  color: var(--kc-blue);
}

/* ##
 * # Featured Projects - Image Links
 * # ---
 * ### */
#kc-featured-projects .image_links a:hover {
  background: var(--kc-dark-blue-tr);
}
#kc-featured-projects .image_links a:hover .path {
  stroke: var(--kc-gold);
}

/* ##
 * # Buttons
 * # ---
 * ### */
.button-stroke input[type=submit]:hover {
  color: #fff !important;
}
.button-stroke a.action_button {
  color: #fff !important;
  background-color: var(--kc-gold) !important;
}
.button-stroke a.action_button:hover {
  background-color: var(--kc-gold-shd1) !important;
}

/* ##
 * # Top Var -- Main Menu
 * # ---
 * ### */
#Top_bar #menu-main-menu {
  /** Sub Menu */
}
#Top_bar #menu-main-menu li.hover {
  background-color: var(--kc-dark-blue-tr);
}
#Top_bar #menu-main-menu .sub-menu {
  background: transparent;
}
#Top_bar #menu-main-menu .sub-menu li {
  background-color: var(--kc-dark-blue-tr);
  width: 250px;
}

#Top_bar:not(.is-sticky) li.hover {
  background-color: var(--kc-dark-blue-tr);
}

#Top_bar.is-sticky .menu > li:not(.current-menu-item) > a {
  color: #fff;
}

/**
 * SubHeader
 * ---
 * */
#Subheader h1 {
  text-shadow: rgb(0, 0, 0) 1px 1px 2px;
  text-align: right;
}

/**
 * Footer
 * ---
 * */
#Footer .footer_copy {
  padding: 20px 0;
}

/* ##
 * # 10. Responsiveness
 * ### */
/* ### 10.1 Mobile ### */
@media only screen and (max-width: 480px) {
  .beReset .google-map-contact-wrapper.style-box {
    margin-left: 0 !important;
  }
}
/* End Mobile */
/* ### 10.2 Tablets ### */
@media only screen and (max-width: 820px) {
  /** Wrap Overlap */
}
/* End Tablets */
@media only screen and (max-width: 1240px) {
  #Top_bar .top_bar_right {
    margin-top: initial !important;
  }
}
/* END Responsiveness */
/**
 * 
 * */
* {
  transition: background 0.2s, color 0.2s, transform 0.2s, border 0.2s, opacity 0.2s, bottom 0.4s, transform 0.3s, height 0.15s;
  transition-timing-function: ease-out;
}

.noresize {
  resize: none;
}

.button_full_width {
  width: 100%;
}