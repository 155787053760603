/* ##
 * # 03. Elements 
 * ### */

.kc-hl-box-gold {
	background-color: var(--kc-gold);
	color: #fff;

	&:hover {
		background-color: var(--kc-gold-shd1);
	}
}

.kc-list-gold li::before {
	color: var(--kc-gold);
}

#kc-home-projects {
	margin-bottom: -180px;
}
