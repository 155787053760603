/**
 * 
 * */

@use 'colors';
@use 'elements';
@use 'reset';
@use 'responsive';

	
* {
	transition: background .2s, color .2s, transform .2s, border .2s, opacity .2s, bottom .4s, transform .3s, height .15s;
	transition-timing-function: ease-out;
}

.noresize { resize: none; }
.button_full_width { width: 100%; }